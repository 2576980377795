import React, { useState, useEffect } from 'react'

const ThemeContext = React.createContext('light')

const ThemeProvider = ({ children }) => {
  const [mode, setPrefersMode] = useState('light')

  useEffect(() => {
    if (window.__theme) {
      setPrefersMode(window.__theme)
      return
    }

    const cookieMode = getCookie('theme')
    if (cookieMode) {
      setPrefersMode(cookieMode)
      return
    }

    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    if (isDarkMode) {
      setPrefersMode('dark')
      return
    }

    // set light as default
    setPrefersMode('light')
  }, [mode])

  const toggleMode = e => {
    if (mode === 'dark') {
      setMode({ mode: 'light' })
    } else {
      setMode({ mode: 'dark' })
    }
  }

  const setMode = ({ event, mode }) => {
    if (event) {
      event.preventDefault()
    }
    setPrefersMode(mode)
    window.__theme = mode
    setCookie('theme', mode)
    document.body.classList = mode
  }

  return (
    <ThemeContext.Provider value={{ mode, setMode, toggleMode }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext
export { ThemeProvider }

function setCookie(name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

function getCookie(name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

import * as React from 'react'
import { Provider } from './context'

const cascadingTags = ['title', 'meta', 'script']

export default class HeadProvider extends React.Component {
  indices = new Map()

  state = {
    addClientTag: (tag, name) => {
      // consider only cascading tags
      if (cascadingTags.indexOf(tag) !== -1) {
        this.setState(state => {
          const names = state[tag] || []
          return { [tag]: [...names, name] }
        })
        // track indices synchronously
        const { indices } = this
        const index = indices.has(tag) ? indices.get(tag) + 1 : 0
        indices.set(tag, index)
        return index
      }
      return -1
    },

    shouldRenderTag: (tag, index) => {
      if (cascadingTags.indexOf(tag) !== -1) {
        const names = this.state[tag]
        // check if the tag is the last one of similar
        return names && names.lastIndexOf(names[index]) === index
      }
      return true
    },

    removeClientTag: (tag, index) => {
      this.setState(state => {
        const names = state[tag]
        if (names) {
          names[index] = null
          return { [tag]: names }
        }
        return null
      })
    },

    addServerTag: tagNode => {
      const headTags = this.props.headTags || []
      // tweak only cascading tags
      if (cascadingTags.indexOf(tagNode.type) !== -1) {
        const index = headTags.findIndex(prev => {
          const prevName = prev.props.name || prev.props.property
          const nextName = tagNode.props.name || tagNode.props.property
          return prev.type === tagNode.type && prevName === nextName
        })
        if (index !== -1) {
          headTags.splice(index, 1)
        }
      }
      headTags.push(tagNode)
    }
  }

  componentDidMount() {
    const ssrTags = document.head.querySelectorAll(`[data-rh=""]`)
    const titleTags = document.head.querySelectorAll(`title`)
    // `forEach` on `NodeList` is not supported in Googlebot, so use a workaround
    Array.prototype.forEach.call(ssrTags, ssrTag =>
      ssrTag.parentNode.removeChild(ssrTag)
    )
    Array.prototype.forEach.call(titleTags, ssrTag =>
      ssrTag.parentNode.removeChild(ssrTag)
    )
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

import { IdleQueue } from 'idlize/IdleQueue.mjs'
/**
 * The property ID for bejamas.io
 */
export const TRACKING_ID = 'UA-118672913-1'

// A queue to ensure all analytics tasks to run when idle.

export const queue =
  typeof window !== 'undefined'
    ? new IdleQueue({
        defaultMinTaskTime: 40, // Only run if there's lots of time left.
        ensureTasksRun: true
      })
    : []

/**
 * A wrapper function for the global `ga()` provided by analytics.js that
 * runs all commands via an IdleQueue.
 * @param {...*} args
 */
export const ga = (...args) => {
  queue.pushTask(() => window.ga(...args))
}

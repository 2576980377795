module.exports = [{
      plugin: require('/zeit/6d4aab17/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"130"},
    },{
      plugin: require('/zeit/6d4aab17/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":870},
    },{
      plugin: require('/zeit/6d4aab17/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bejamas","short_name":"Bejamas","start_url":"/?utm_source=a2hs","background_color":"#ffffff","theme_color":"#f94062","display":"minimal-ui","icon":"src/assets/bejamas-icon2.png"},
    },{
      plugin: require('/zeit/6d4aab17/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://bejamas.io","stripQueryString":true},
    },{
      plugin: require('/zeit/6d4aab17/plugins/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-118672913-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/zeit/6d4aab17/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { TRACKING_ID, queue, ga } from './src/utils/analytics'
import { HeadProvider } from 'react-head'
import { ThemeProvider } from './src/components/ThemeContext'

export const wrapRootElement = ({ element }) => {
  return (
    <HeadProvider>
      <ThemeProvider>{element}</ThemeProvider>
    </HeadProvider>
  )
}

export const onClientEntry = () => {
  // special message for hackers
  // console.info(
  //   "🚀 Keep looking, you're almost there! That's great you are curious about what's behind! 🙂 \n🔥 Send us an email careers@bejamas.io"
  // )
  window.ga =
    window.ga ||
    function() {
      ;(ga.q = ga.q || []).push(arguments)
    }

  ga('create', TRACKING_ID, 'auto', {})
  ga('set', 'anonymizeIp', true)

  // mouseflow
  // queue.pushTask(() => {
  //   window._mfq = window._mfq || []
  // })
}

const addPageToVisited = pathname => {
  queue.pushTask(() => {
    let pages = [pathname]
    const visitedPages = localStorage.getItem('visitedPages') || null
    if (visitedPages) {
      pages = [visitedPages, pathname]
    }

    localStorage.setItem('visitedPages', pages)
  })
}

export const onRouteUpdate = ({ location }) => {
  addPageToVisited(location.pathname)

  if (process.env.NODE_ENV !== `production` || typeof ga !== `function`) {
    return null
  }

  const pathIsExcluded =
    location &&
    typeof window.excludeGAPaths !== `undefined` &&
    window.excludeGAPaths.some(rx => rx.test(location.pathname))

  if (pathIsExcluded) return null

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined

    ga(`set`, `page`, pagePath)
    ga(`send`, `pageview`)
  }

  // Minimum delay for reactHelmet's requestAnimationFrame
  const delay = Math.max(32, 0)
  setTimeout(sendPageView, delay)

  return null
}
